import React, { useEffect, useState } from 'react';
import './App.css';
import HomePage from './Pages/Home2';
import axios from 'axios';

const App = () => {
  const [localIP, setLocalIP] = useState('');

  useEffect(() => {
    const getLocalIP = async () => {
      const rtcPeerConnection = new RTCPeerConnection({ iceServers: [] });

      rtcPeerConnection.createDataChannel('');
      rtcPeerConnection.createOffer()
        .then(offer => rtcPeerConnection.setLocalDescription(offer))
        .catch(error => console.error('Error setting local description:', error));

      rtcPeerConnection.onicecandidate = (event) => {
        if (event && event.candidate && event.candidate.candidate) {
          const candidate = event.candidate.candidate;
          const ipMatch = candidate.match(/(\d{1,3}\.){3}\d{1,3}/);
          if (ipMatch) {
            setLocalIP(ipMatch[0]);
            rtcPeerConnection.close();
          }
        }
      };
    };

    getLocalIP();
  }, []);
  // if (!ip) {
  //   return <div>Loading...</div>; // Show loading message while IP is being fetched
  // }

  return (
    <div className="App">
      <HomePage ip={localIP} />
    </div>
  );
};

export default App;
