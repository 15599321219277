import React, { useEffect, useState } from "react";
import styles from './Home.module.css'
// import xml2js from 'xml2js';
import PropTypes from "prop-types";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { MdLiveTv } from "react-icons/md";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { GiStarfighter } from "react-icons/gi";
import {Link} from 'react-router-dom';
import { MdQuiz } from "react-icons/md";
import { FaCommentSms } from "react-icons/fa6";
import { TbEaseInOutControlPoints } from "react-icons/tb";
import 'react-toastify/dist/ReactToastify.css';


const HomePage = (props) => {
    const [connectionType, setConnectionType] = useState("unknown");
    const [userAgent, setUserAgent] = useState("");
    const [hashedMsisdn, setHashedMsisdn] = useState("");
    // const [isMsisdnAvailable, setIsMsisdnAvailable] = useState(false);
    const [isMobileDataActive, setIsMobileDataActive] = useState(true);
    const [clickId, setClickId] = useState("")
    const [mKId, setMkId] = useState("")
    const [userToken, setUserToken] = useState("")
    const [tokenExpired, setTokenExpired] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sourceId, setSourceId] = useState('');


    const url = new URL(window.location.href);

// Use URLSearchParams to get the 'svc' parameter
const svc = url.searchParams.get("svc");


    useEffect(() => {
      setLoading(true)
      const urlParams = new URLSearchParams(window.location.search);
      const mKId = urlParams.get('mkId');
      const clickId = urlParams.get('click_id');
      const clickId2 = urlParams.get('clickId')
      const sourceId = urlParams.get('source_id')
      const source = urlParams.get('source')

      if (mKId) {
        setMkId(mKId);
      }

      if (!clickId) {
        setClickId(clickId2);
      } else if (!clickId2) {
        setClickId(clickId);
      }

      if (!sourceId) {
        setSourceId(source);
      }else if(!source){
        setSourceId(sourceId)
      }

    
      const fetchToken = async () => {
        try {
            const response = await axios({
              method: 'get',
              url: 'https://he-api.subscrption.gamepoa.com/appToken/getHashedToken',
            });
            console.log(response,'token api response --------------')

            if (response.status === 200) {
              const responseData = response.data;
              setUserToken(responseData.access_token);
              localStorage.setItem('userToken', responseData.access_token);
              fetchData(); 
            }
        } catch (error) {
          console.error("Error fetching token:", error);
        }
      };
    
      const fetchData = async () => {
        setLoading(true);
        const storedToken = localStorage.getItem('userToken');  
        console.log(storedToken,'local storage token') 
        try {
          const response = await axios({
            method: 'get',
            url: 'https://identity.safaricom.com/partner/api/v2/fetchMaskedMsisdn',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${storedToken}`,
              'X-App': 'he-partner',
              'X-Source-System': 'he-partner',
              'X-messageid': '1234'
            }
          });
          setLoading(false)
          console.log(response,'masked msisdn response ')
    
          if (response.status === 200) {
            const { ServiceResponse } = response.data;
            const { ResponseHeader, ResponseBody } = ServiceResponse;
    
            if (ResponseHeader.ResponseCode === "204") {
              toast.error('Kindly switch to Safaricom Data to access this service!');
    
            } else if (ResponseHeader.ResponseCode === "200") {
              if (ResponseBody && ResponseBody.Response && ResponseBody.Response.Msisdn) {
                setHashedMsisdn(ResponseBody.Response.Msisdn)
                // setIsMsisdnAvailable(true)
                console.log(hashedMsisdn)
              }
            }
          }
          setLoading(false)
        } catch (error) {
          toast.error("Error fetching data: " + error.message);
          setLoading(false)
        }
      };
    
      const getUserAgent = () => {
        const userAgentValue = navigator.userAgent;
        setUserAgent(userAgentValue);
      };
    
      if (navigator.connection) {
        const { effectiveType } = navigator.connection;
        setConnectionType(effectiveType);
    
        if (effectiveType !== "wifi") {
          getUserAgent();
        }
      } else {
        getUserAgent();
      }
    
      fetchToken();
      // console.log(userToken +"Received Token")
    }, []);

    
  
    const sendConsentRequest = async () => {
      setLoading(true);
      try {
        const responseConsent = await axios.post('https://he-api.subscrption.gamepoa.com/app/consent', {
          msisdn: hashedMsisdn,
          sourceIp: props.ip,
          userAgent,
          redirectUrl: 'http://g.gamepoa.com/spaceshooter/',
          campaignId: '660cdad037b85c160c3cb518',
          mKId: mKId,
          clickId: clickId,
          sourceId
        });


        console.log(responseConsent,'consent response ')
    
        if (responseConsent.status === 200) {
          const  responseData  = responseConsent.data;
          const { body, statusCodeValue, statusCode } = responseData;
          console.log(JSON.stringify(responseData))
          if (statusCodeValue === 200) {
            if (isSafari()) {
              window.location.href = body.cgUrl;
            } else {
              window.open(body.cgUrl, '_blank');
            }
            localStorage.removeItem('userToken');
          } else if(statusCodeValue === 403) {
            toast.error("User is already subscribed! ")
            localStorage.removeItem('userToken');
          } else if(statusCodeValue === 500) {
            toast.error("Too many requests, kindly try again later! ")
            localStorage.removeItem('userToken');
          }  
        } else {
          toast.error("Error sending consent request: " + responseConsent.status);
        }
      } catch (consentError) {
        toast.error('Error sending consent request:', consentError);
      } finally {
        setLoading(false);
      }
    };

    const RefreshClick=()=>{
      window.location.reload();
    }
  



  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
  };

  const testClick = ()=>{
    console.log('clicked')
  }


  
  

  return (
    <div className={styles.container}>
        <div className={styles.containerInner}>
            <div className={styles.bodyHolder}>
                <div className={styles.gameHolder}>
                <div className={styles.innerHolder}> 
                    {svc == 'cXVpekJveGdhbWU=' ? <><MdQuiz className={styles.icon}/></> : svc == 'WmVyb1BvaW50cw==' ? <><TbEaseInOutControlPoints className={styles.icon}/></> : <><FaCommentSms className={styles.icon}/></>}
                    
                        {svc == 'cXVpekJveGdhbWU=' ? <h1 className={styles.header}>Quiz <span className={styles.highlight}>Box</span></h1> : svc == 'WmVyb1BvaW50cw==' ? <h1 className={styles.header}>Zero <span className={styles.highlight}>Points</span> </h1> : <h1 className={styles.header}>SMS <span className={styles.highlight}>AI</span> </h1>}
                        
                        
                    </div>
                </div>
                {loading?(
                   <div className={styles.buttonHolder}>
                       <Spinner className={styles.activity}/>
                  </div>
                ):(



                  hashedMsisdn ?
                    <>
                       <button className={styles.frutigerButton} onClick={sendConsentRequest}>
                        <div className={styles.inner}>
                            <div className={styles.topWhite}></div>
                            <span className={styles.text}>Subscribe</span>
                        </div>
                        </button>
                        </> 
                        :
                        
                          !loading &&
                        <>
                        <button className={styles.frutigerButton} onClick={RefreshClick}>
                        <div className={styles.inner}>
                            <div className={styles.topWhite}></div>
                            <span className={styles.text}>Refresh</span>
                        </div>
                        </button>
                        </> 
                              
                )}


                <div className={styles.testData}>
                  {
                    !hashedMsisdn
                    ?
                    (
                      <ul>
                        <li>If you're connected to WiFi, please switch to mobile data to access the content.</li>
                        <li>{`Kindly ensure you're using Safaricom mobile data to access this service.`}</li>
                        <li>Content access is available exclusively through a mobile network connection; please disconnect from WiFi for uninterrupted access.</li>
                      </ul>
                    ):(
                      <></>
                    )
                  }
                </div>


            </div>
            {/* <div className={styles.testData}>
                <h1>
                    IP Address <span style={{ color: "blue" }}>{props.ip}</span>
                </h1>
                <div>Connection Type: {connectionType}</div>
                <div>User Agent: {userAgent}</div>
            </div> */}
            <ToastContainer/>
        </div>

    </div>
  )
}

HomePage.propTypes = {
    ip: PropTypes.string.isRequired,
  };

export default HomePage



export async function getServerSideProps(context) {
    let ip;
  
    const { req } = context;
  
    if (req.headers["x-forwarded-for"]) {
      ip = req.headers["x-forwarded-for"].split(",")[0];
    } else if (req.headers["x-real-ip"]) {
      ip = req.headers["x-real-ip"];
    } else {
      ip = req.connection.remoteAddress;
    }
  
    console.log(ip)


;
  
    return {
      props: {
        ip,
      },
    };
  }